<template>
  <div>
    <!-- picE557A0F0CE6C12CC2DF9DB4F885961E3.jpg -->
    <img class="img-large rounded" src="">
    <p>
      With the arrival of the final pod, another four letters will need to be found in the data from Pod 7.
    </p>
    <p>
      The scientists at the Bradford Rant Institute believe that when these are added to the letters found in the other six pods — and put in the right order — a message will be formed.
    </p>
    <p>
      They have created a window on their website into which you can enter the message once you figure it out. What will happen when the message is entered, they do not know. But they urge all fans to find the last four letters in the data, put the message together and find out!
    </p>
    <p>
      To take part in this important event, go to
      <a href="http://www.bradfordrant.org/en/reveal_the_mystery">www.Bradfordrant.org</a>.
    </p>
  </div>
</template>
